const btn = document.querySelector("#button");
const component = document.querySelector("#component");
const thankYouComponent = document.querySelector(".thank-you-component");
const componentNumbers = document.querySelectorAll(".component__number");
console.log(componentNumbers);

componentNumbers.forEach((button, index) => {
  button.onclick = (event) => {
    const clickedNr = index;
    btn.addEventListener("click", function () {
      component.classList.add("hide");
      thankYouComponent.classList.add("show");
      document.querySelector(
        ".thank-you-component__rating"
      ).textContent = `You selected ${clickedNr + 1} out of 5`;
    });
  };
});
